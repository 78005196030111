export default {
  additionalResources: 'additionalResources',
  announcements: 'announcements',
  assetAssociatedInventoryReport: 'assetAssociatedInventoryReport',
  assetCategories: 'assetCategories',
  assetCostHistoryReport: 'assetCostHistoryReport',
  assetDowntimeDetailReport: 'assetDowntimeDetailReport',
  assetDowntimeSummaryReport: 'assetDowntimeSummaryReport',
  assetProductAssetsReport: 'assetProductAssetsReport',
  assetRentalReport: 'assetRentalReport',
  assetToleranceReport: 'assetToleranceReport',
  assetTransferReport: 'assetTransferReport',
  assetUtilizationReport: 'assetUtilizationReport',
  assetWarrantyExpiryReport: 'assetWarrantyExpiryReport',
  assets: 'assets',
  cardinalReport: 'cardinalReport',
  cbVmiReport: 'cbVmiReport',
  customMappings: 'customMappings',
  customerDetails: 'customerDetails',
  customerGroups: 'customerGroups',
  customerUsageReport: 'customerUsageReport',
  customers: 'customers',
  cycleCounts: 'cycleCounts',
  dashboardCharts: 'dashboardCharts',
  dashboardTemplates: 'dashboardTemplates',
  dashboardThemes: 'dashboardThemes',
  dataTransferActions: 'dataTransferActions',
  dataTransferAuditReport: 'dataTransferAuditReport',
  documents: 'documents',
  entityAuditReport: 'entityAuditReport',
  entityLists: 'entityLists',
  excessInventoryReport: 'excessInventoryReport',
  externalApprovals: 'externalApprovals',
  fastenalSafetyReport: 'fastenalSafetyReport',
  fileTemplates: 'fileTemplates',
  filterTemplates: 'filterTemplates',
  formBatches: 'formBatches',
  formDataReport: 'formDataReport',
  formGroups: 'formGroups',
  formInstances: 'formInstances',
  formSubmissionReport: 'formSubmissionReport',
  formTemplates: 'formTemplates',
  inventory: 'inventory',
  inventoryCycleCountDifferenceReport: 'inventoryCycleCountDifferenceReport',
  inventoryCycleCountFrequencyReport: 'inventoryCycleCountFrequencyReport',
  inventoryDeficiencyReport: 'inventoryDeficiencyReport',
  inventoryIssueStatusReport: 'inventoryIssueStatusReport',
  inventoryLastActivityReport: 'inventoryLastActivityReport',
  inventoryLastUsedReport: 'inventoryLastUsedReport',
  inventoryLocationReport: 'inventoryLocationReport',
  inventoryMinMaxReport: 'inventoryMinMaxReport',
  inventoryMonthlyChangeReport: 'inventoryMonthlyChangeReport',
  inventoryOnHandExceptionReport: 'inventoryOnHandExceptionReport',
  inventoryReceivingAuditReport: 'inventoryReceivingAuditReport',
  inventoryReceivingDetailReport: 'inventoryReceivingDetailReport',
  inventoryReturnedItemsReport: 'inventoryReturnedItemsReport',
  inventoryTransferReport: 'inventoryTransferReport',
  inventoryUsageReport: 'inventoryUsageReport',
  issueLimitLists: 'issueLimitLists',
  itemHistoryReport: 'itemHistoryReport',
  jobAuditReport: 'jobAuditReport',
  jobCompletionDetailReport: 'jobCompletionDetailReport',
  jobCompletionSummaryReport: 'jobCompletionSummaryReport',
  jobCostReport: 'jobCostReport',
  jobGroups: 'jobGroups',
  jobLabourRates: 'jobLabourRates',
  jobProgressReport: 'jobProgressReport',
  jobReasonGroups: 'jobReasonGroups',
  jobReasonReport: 'jobReasonReport',
  jobReasons: 'jobReasons',
  jobRequests: 'jobRequests',
  jobStatusOptions: 'jobStatusOptions',
  jobUsageReport: 'jobUsageReport',
  jobs: 'jobs',
  labelTemplates: 'labelTemplates',
  lists: 'lists',
  locationCounts: 'locationCounts',
  locationGroups: 'locationGroups',
  locationOrderReport: 'locationOrderReport',
  locations: 'locations',
  monthlyDetailReport: 'monthlyDetailReport',
  notifications: 'notifications',
  onTimeDeliveryReport: 'onTimeDeliveryReport',
  openOrdersReport: 'openOrdersReport',
  operatorGroups: 'operatorGroups',
  operatorIssueLimitLists: 'operatorIssueLimitLists',
  operators: 'operators',
  orderAuditReport: 'orderAuditReport',
  orderFrequencyReport: 'orderFrequencyReport',
  orders: 'orders',
  overstockInventory: 'overstockInventory',
  overstockInventoryCycleCountDifferenceReport: 'overstockInventoryCycleCountDifferenceReport',
  overstockLocations: 'overstockLocations',
  priceSavingsReport: 'priceSavingsReport',
  productCategories: 'productCategories',
  products: 'products',
  profiles: 'profiles',
  punchout: 'punchout',
  reportSchedules: 'reportSchedules',
  requisitionAuditReport: 'requisitionAuditReport',
  requisitionItemsReport: 'requisitionItemsReport',
  requisitions: 'requisitions',
  returnToInventory: 'returnToInventory',
  rfqAuditReport: 'rfqAuditReport',
  rfqs: 'rfqs',
  stevensReport: 'stevensReport',
  supplierDetails: 'supplierDetails',
  supplierIntegration: 'supplierIntegration',
  suppliers: 'suppliers',
  tagLists: 'tagLists',
  tasks: 'tasks',
  tenantSettings: 'tenantSettings',
  tenants: 'tenants',
  ticketCategories: 'ticketCategories',
  tickets: 'tickets',
  transactionAuditReport: 'transactionAuditReport',
  unitsOfMeasure: 'unitsOfMeasure',
  usageOrderReport: 'usageOrderReport',
  userActionReport: 'userActionReport',
  userGroups: 'userGroups',
  userTypes: 'userTypes',
  users: 'users',
}
