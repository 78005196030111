import produce from 'immer'
import merge from 'deepmerge'
import {
  get,
  toString as str,
  isNaN,
  trim,
  isArray,
  isPlainObject,
  camelCase,
  toLower,
  orderBy,
  isString,
  uniqBy,
  lowerCase,
  isEmpty,
  trimStart,
} from 'lodash'
import { Base64 } from 'js-base64'

export const HARD_SPACE = '\u00A0'
export const DEBOUNCE = 1000

export const asyncSleep = (timeout = 1000) => new Promise((resolve) => window.setTimeout(resolve, timeout))

export const strEqual = (x, y) => toLower(trim(x)) === toLower(trim(y))

export const mergeAll = (...objects) => merge.all(objects, { arrayMerge: (destination, source, options) => source })

export const tryParseFloat = (value, defaultValue = 0.0) => {
  const parsed = Number.parseFloat(str(value))

  return isNaN(parsed) ? defaultValue : parsed
}

export const tryParseInt = (value, defaultValue = 0) => {
  const parsed = Number.parseInt(str(value))

  return isNaN(parsed) ? defaultValue : parsed
}

export const tryParseJSON = (value, defaultValue = {}) => {
  try {
    return JSON.parse(str(value))
  } catch (error) {
    return defaultValue
  }
}

export const tryParseArray = (value, defaultValue = []) => {
  try {
    const parsed = JSON.parse(`[${trim(value, '[]')}]`)

    return isArray(parsed) ? parsed : defaultValue
  } catch (error) {
    return defaultValue
  }
}

export function normalizeJSON(parsed) {
  if (isArray(parsed)) {
    return parsed.map(normalizeJSON)
  }

  if (isPlainObject(parsed)) {
    return Object.keys(parsed).reduce((acc, each) => ({ ...acc, [camelCase(each)]: normalizeJSON(parsed[each]) }), {})
  }

  return parsed
}

export const moveByDelta = (array = [], index = -1, delta = 0) => {
  const next = index + delta

  if (index === -1 || delta === 0 || next < 0 || next === array.length) {
    return array
  }

  const copy = [...array]
  const [start, finish] = [index, next].slice().sort((a, b) => a - b)

  copy.splice(start, 2, copy[finish], copy[start])

  return copy
}

export const sortByKeys = (array = [], sortedKeys = [], keyField = 'key') =>
  array.slice().sort((a, b) => sortedKeys.indexOf(a[keyField]) - sortedKeys.indexOf(b[keyField]))

export const createResetState =
  (self, ...params) =>
  (...otherParams) =>
    new Promise((resolve) => {
      self.setState(
        produce((draft) => {
          try {
            Object.assign(draft, mergeAll({}, ...params, ...otherParams))
          } catch (error) {
            console.warn(error)
          }
        }),
        resolve
      )
    })

export const sortItems = (unsortedItems, sortByField, sortOrder) =>
  sortByField
    ? orderBy(
        unsortedItems,
        [
          (item) => {
            const sortByValue = get(item, sortByField)
            return isString(sortByValue) ? toLower(sortByValue) : sortByValue
          },
        ],
        [['desc', 'descend'].includes(toLower(sortOrder)) ? 'desc' : 'asc']
      )
    : unsortedItems

export const findUniqOrFirst = (items, path, value) => {
  const results = uniqBy(items, path)

  return results.length === 1
    ? results[0]
    : results.find(
        (one) =>
          lowerCase(get(one, path)) === lowerCase(value) ||
          lowerCase(get(one, path) + ' ').startsWith(lowerCase(value) + ' ') // eslint-disable-line prefer-template
      )
}

export const isSignificant = (value) =>
  value !== null &&
  value !== undefined &&
  value !== false &&
  value !== 0 &&
  value !== '' &&
  value !== 'All' &&
  value !== 'None' &&
  JSON.stringify(value) !== '[]' &&
  JSON.stringify(value) !== '{}'

export const tryParseQueryString = (value) => {
  try {
    const trimmed = trimStart(value, '?#')

    return isEmpty(trimmed) ? {} : Object.fromEntries(new URLSearchParams(trimmed))
  } catch (error) {
    console.warn(error)

    return {}
  }
}

export const getTenantDisplayName = (tenant) =>
  isEmpty(tenant.name)
    ? tenant.subdomain
    : tenant.name === tenant.subdomain || isEmpty(tenant.subdomain)
      ? tenant.name
      : `${tenant.name} (${tenant.subdomain})`

export const encodeState = (state = {}) => Base64.encodeURI(JSON.stringify(state))

export const decodeState = (encoded = trimStart(window.location.search, '?')) => {
  try {
    return !isEmpty(encoded) ? JSON.parse(Base64.decode(encoded)) : {}
  } catch (error) {
    return {}
  }
}
