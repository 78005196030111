import * as React from 'react'
import styled from 'styled-components'
import { Form, Input, Spin, Button, Dropdown, Menu, Tooltip, Card, Popover, message, notification } from 'antd'
import {
  isNil,
  set,
  get,
  isEmpty,
  isEqual,
  remove,
  cloneDeep,
  max,
  uniqBy,
  toString as str,
  range,
  unset,
} from 'lodash'
import produce from 'immer'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { showError, resourceNotFound, ValidationError } from 'helpers/errors'
import { createLabelFactory, createFieldFactory, createHandleSubmit, isReadOnly } from 'helpers/formViews'
import { HARD_SPACE, strEqual } from 'helpers/utils'
import { t } from 'helpers/i18n'
import chartAccessTypes from 'options/dashboards/chartAccessTypes'
import { getSectionProps, getChartWidth, getTotalWidth, sortCharts, createChart } from 'helpers/dashboards'
import chartTypes from 'options/dashboards/chartTypes'
import { formatUserTime } from 'helpers/dateTime'
import { stopEvent } from 'helpers/events'
import FormButtons from 'elements/FormButtons'
import Select, { Option, OptGroup } from 'elements/Select'
import Checkbox from 'elements/Checkbox'
import Icon from 'elements/Icon'
import Drawer from 'elements/Drawer'
import Modal from 'elements/Modal'
import InputNumber from 'elements/InputNumber'
import SectionFormView from 'containers/DashboardTemplates/FormView/Section/FormView'
import ChartFormView from 'containers/DashboardTemplates/FormView/Chart/FormView'
import Chart from './Chart'
import Settings from 'components/DashboardCharts/FormView/Settings'
import SelectDashboardTemplate from 'containers/DashboardTemplates/Select'
import Metrics from 'containers/DashboardTemplates/FormView/Metrics'
import { Row, Col } from 'elements/Grid'

const Container = styled.div`
  .ant-tabs-nav-scroll {
    min-height: 46px;
  }

  .ant-tabs-tab {
    padding-right: 8px !important;

    .ant-btn-link {
      color: unset !important;
      margin-left: 8px;
      padding: 1px 0;

      .anticon {
        margin: 0 !important;
      }
    }

    &:hover,
    &.ant-tabs-tab-active {
      color: unset !important;
    }
  }
`

const Section = styled(Card)`
  background: none;
  border-width: 1px;
  border-color: ${(props) => (props.bordered ? '#d9d9d9' : '#e0e0e0')};
  border-style: ${(props) => (props.bordered ? 'solid' : 'dashed')};

  .ant-card-head {
    padding: 0 12px;
    border-bottom: none;

    .ant-card-head-title {
      padding: 12px 0;
    }

    .ant-card-extra {
      padding: 12px 0;

      .ant-btn-link {
        color: unset;
      }
    }
  }

  .ant-card-body {
    padding: 12px;
    padding-bottom: 0 !important;
  }

  &.ant-card + .ant-card {
    border-top: none !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
`

const Actions = styled.div`
  text-align: right;
  white-space: nowrap;
  height: 16px;

  .ant-btn-link {
    color: #333;
    margin-left: 3px;
  }
`

const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: ${(props) => `${props.sectionHeight}px`};
`

const LineItem = styled.div`
  padding-left: 6px;
  padding-right: 6px;
  user-select: none;
`

const SideHeader = styled.div`
  margin: 0 -24px;
  padding: 12px 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

const SideLine = styled.div`
  display: flex;
  flex-direction: row;
`

const SideLineItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100px;
  height: 100px;
  border: ${(props) => (props.isDragging ? '1px dashed #d9d9d9' : 'none')};

  &:hover {
    border: 1px dashed #d9d9d9;
  }
`

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.handleSubmit = createHandleSubmit(this)
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.fetchItem()
    this.fetchChartMetricTypes()
  }

  transformSaveItemParams = ({ item }) => ({
    sections: produce(item.sections, (draft) => {
      draft.forEach((section) => {
        if (section.id <= 0) {
          unset(section, 'id')
        }

        if (item.id) {
          section.dashboardTemplateId = item.id
        } else {
          unset(section, 'dashboardTemplateId')
        }

        section.charts.forEach((chart) => {
          if (chart.id <= 0) {
            unset(chart, 'id')
          }

          if (section.id) {
            chart.sectionId = section.id
          } else {
            unset(chart, 'sectionId')
          }
        })
      })
    }),
  })

  getItemId = () =>
    [
      this.state.item?.id,
      this.props.linkTargetRecord?.dashboardTemplateId,
      this.props.linkTargetRecord?.templateId,
    ].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        itemId
          ? this.props.getItem(itemId).catch((error) => {
              this.props.onCancel?.()
              throw error
            })
          : !this.props.linkTargetRecord
            ? this.props.newItem({})
            : resourceNotFound(this),
        this.props.getSettings({ type: this.props.settingsType }),
        this.props.getSettings({ type: 'chart' }), // HACK: Making sure this is cached
      ])

      const item = produce(responses[0].value.data, (draft) => {
        let seed = Date.now() * -1

        draft.sections.forEach((section) => {
          section.id = section.id || seed--
        })
      })

      if (item?.accessType === 'Private' && !strEqual(item?.createdBy, this.props.user.userName)) {
        notification.error({
          message: t('unauthorizedAccessTitle'),
          description: t('unauthorizedAccessDescription'),
        })
        this.props.onCancel()
      }

      this.setState({
        item,
        fieldSettings: responses[1].value.data.fieldSettings,
      })
    } catch (error) {
      showError({ error })
    }
  }

  fetchChartMetricTypes = async () => {
    try {
      const chartMetricTypes = await this.props.getChartMetricTypes().then((r) => get(r, 'value.data.items', []))

      this.setState({ chartMetricTypes })

      if (!isEmpty(chartMetricTypes)) {
        this.setPrimaryMetricType(chartMetricTypes[0].type)
      }
    } catch (error) {
      showError({ error })
    }
  }

  setPrimaryMetricType = async (primaryMetricType) => {
    try {
      const response = await this.props.getChartTypes({ primaryMetricType })

      this.setState({
        primaryMetricType,
        primaryMetricCharts: get(response, 'value.data.items', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        this.props.form.setFieldsValue({ [name]: value })
      })
    )

  handleActionsMenuClick = ({ key }) => {
    switch (key) {
      case 'copyFromExistingRecord':
        this.setState({
          copyFromExistingVisible: true,
          copyFromExistingSelectedRecord: null,
        })
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  createHandleAddSectionClick = () => () =>
    this.setState({
      sectionDrawerVisible: true,
      sectionDrawerTitle: t('addSection'),
      sectionDrawerLinkTargetRecord: null,
    })

  createHandleEditSectionClick = (section) => () =>
    this.setState({
      sectionDrawerVisible: true,
      sectionDrawerTitle: section.name,
      sectionDrawerLinkTargetRecord: section,
    })

  handleSectionDrawerClose = () =>
    this.setState({
      sectionDrawerVisible: false,
      sectionDrawerLinkTargetRecord: null,
    })

  handleSectionDrawerSaveAndClose = (saved) => {
    if (!saved.id) {
      saved.id = Date.now() * -1
    }

    this.setState(
      produce((draft) => {
        remove(draft.item.sections, (one) => one.id === saved.id)

        draft.item.sections.splice(saved.position - 1, 0, { ...saved })

        draft.item.sections.forEach((section, index) => {
          section.position = index + 1
        })

        draft.sectionDrawerVisible = false
      })
    )
  }

  createHandleAddChartClick = (section) => () =>
    this.setState({
      chartDrawerVisible: true,
      chartDrawerTitle: t('addChart'),
      chartDrawerLinkTargetRecord: null,
      chartDrawerParentRecord: section,
    })

  handleChartDrawerClose = () =>
    this.setState({
      chartDrawerVisible: false,
      chartDrawerLinkTargetRecord: null,
      chartDrawerParentRecord: null,
    })

  deleteSection = (sectionIndex) => {
    Modal.confirm({
      autoFocusButton: 'ok',
      title: t('confirmDeleteDashboardSection'),
      okType: 'danger',
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk: () =>
        this.setState(
          produce((draft) => {
            draft.item.sections.splice(sectionIndex, 1)

            draft.item.sections.forEach((section, index) => {
              section.position = index + 1
            })
          })
        ),
    })
  }

  handleDragEnd = async ({ draggableId, source, destination }) => {
    if (isEmpty(destination) || isEqual(source, destination)) {
      return
    }

    const { chartId, chartType } = JSON.parse(draggableId)
    const sourceIds = JSON.parse(source.droppableId)
    const destinationIds = JSON.parse(destination.droppableId)

    let dimensionTitle = ''
    let dimensionIdentifier = ''

    if (chartType) {
      try {
        const { primaryMetricType, chartMetricTypes } = this.state
        const primaryMetric = chartMetricTypes.find((one) => one.type === primaryMetricType)
        const { defaultDimensionType } = primaryMetric

        const dimensionType = await this.props
          .getDimensionTypes({
            chartType,
            primaryMetricType,
            secondaryMetricType: 'None',
          })
          .then((r) => r.value.data.items.find((one) => one.type === defaultDimensionType))

        dimensionTitle = dimensionType?.name
        dimensionIdentifier = dimensionType?.identifier
      } catch (error) {
        showError({ error })
      }
    }

    this.setState(
      produce((draft) => {
        const snapshot = cloneDeep(draft.item)

        try {
          const section = draft.item.sections.find((one) => one.id === destinationIds.sectionId)

          if (chartId) {
            const from = draft.item.sections.find((one) => one.id === sourceIds.sectionId)
            const index = from.charts.findIndex((one) => one.id === chartId)
            const [chart] = from.charts.splice(index, 1)

            chart.position = destination.index
            chart.sectionId = section.id

            section.charts = sortCharts(section.charts, chart)
            from.charts = sortCharts(from.charts)
          } else if (chartType) {
            const primaryMetric = draft.chartMetricTypes.find((one) => one.type === draft.primaryMetricType)
            const {
              name: primaryMetricTitle,
              type: primaryMetricType,
              identifier: primaryMetricIdentifier,
              defaultDimensionType,
              defaultDistributionIdentifier,
            } = primaryMetric

            const chart = createChart({
              id: Date.now() * -1,
              sectionId: section.id,
              position: destination.index,
              primaryMetricType,
              chartType,
              dimensionType: ['Gauge', 'Scorecard'].includes(chartType) ? 'None' : defaultDimensionType,
              name: primaryMetric.name,
              chartJson: JSON.stringify({
                primaryMetricTitle,
                primaryMetricIdentifier,
                dimensionTitle,
                dimensionIdentifier,
                distributionIdentifier: defaultDistributionIdentifier,
              }),
            })

            section.charts = sortCharts(section.charts, chart)
          } else {
            message.warn(t('underDevelopment'))
          }
        } catch (error) {
          draft.item = snapshot

          if (error.message === 'dashboardSectionOverflowError') {
            notification.error({
              message: t('dashboardSectionOverflowError'),
              description: t('dashboardSectionOverflowErrorDescription'),
            })
          } else {
            console.error(error)
          }
        }
      })
    )
  }

  changeChartSettings = (chart, value) =>
    this.setState(
      produce((draft) => {
        const section = draft.item.sections.find((one) => one.id === chart.sectionId)
        const selected = section.charts.find((one) => one.id === chart.id)
        const snapshot = cloneDeep(section.charts)

        Object.assign(
          snapshot.find((one) => one.id === chart.id),
          value
        )

        if (getTotalWidth(snapshot) <= 24) {
          Object.assign(selected, value)
        } else {
          notification.error({
            message: t('dashboardSectionOverflowError'),
            description: t('dashboardSectionOverflowErrorDescription'),
          })
        }
      })
    )

  handleChartDrawerSaveAndClose = (saved) => {
    if (!saved.id) {
      saved.id = Date.now() * -1
    }

    this.setState(
      produce((draft) => {
        const snapshot = cloneDeep(draft.item)

        try {
          draft.item.sections.forEach((eachSection) => {
            remove(eachSection.charts, (one) => one.id === saved.id)
          })

          const section = draft.item.sections.find((one) => one.id === saved.sectionId)

          section.charts = sortCharts(section.charts, saved)

          draft.item.sections.forEach((eachSection) => {
            eachSection.charts = sortCharts(eachSection.charts)
          })

          draft.chartDrawerVisible = false
        } catch (error) {
          draft.item = snapshot

          if (error.message === 'dashboardSectionOverflowError') {
            notification.error({
              message: t('dashboardSectionOverflowError'),
              description: t('dashboardSectionOverflowErrorDescription'),
            })
          } else {
            console.error(error)
          }
        }
      })
    )
  }

  deleteChart = (chart) =>
    this.setState(
      produce((draft) => {
        draft.item.sections.forEach((section) => {
          remove(section.charts, (one) => one.id === chart.id)
          section.charts = sortCharts(section.charts)
        })
      })
    )

  duplicateChart = (chart) =>
    this.setState(
      produce((draft) => {
        const section = draft.item.sections.find((one) => one.id === chart.sectionId)

        const cloned = cloneDeep(chart)

        cloned.id = Date.now() * -1
        cloned.position = max(section.charts.map((each) => each.position)) + 1

        if (getTotalWidth([...section.charts, cloned]) <= 24) {
          section.charts.push(cloned)
        } else {
          notification.error({
            message: t('dashboardSectionOverflowError'),
            description: t('dashboardSectionOverflowErrorDescription'),
          })
        }
      })
    )

  handleCopyFromExisting = async () => {
    try {
      this.setState({ copyFromExistingLoading: true })

      const { sections, filterJson } = await this.props
        .getItem(this.state.copyFromExistingSelectedRecord.id)
        .then((r) => r.value.data)
        .then(cloneDeep)

      this.setState(
        produce((draft) => {
          draft.item.sections = sections
          draft.item.filterJson = filterJson

          let seed = Date.now() * -1

          draft.item.sections.forEach((section) => {
            set(section, 'id', seed--)
            unset(section, 'dashboardTemplateId')

            section.charts.forEach((chart) => {
              set(chart, 'id', seed--)
              set(chart, 'sectionId', section.id)
            })
          })

          draft.copyFromExistingVisible = false
          draft.copyFromExistingSelectedRecord = null
        })
      )
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ copyFromExistingLoading: false })
    }
  }

  render() {
    const { item, fieldSettings, chartMetricTypes = [], primaryMetricCharts } = this.state

    if (!item || !fieldSettings) {
      return <Spin />
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)

    const readOnly = isReadOnly(this) || !strEqual(item.createdBy, this.props.user.userName)

    const chartMetricCategoryTypes = uniqBy(
      chartMetricTypes.map(({ categoryType, categoryName }) => ({
        categoryType,
        categoryName,
      })),
      'categoryType'
    )

    return (
      <>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Container ref={this.containerRef}>
            <Form layout="vertical" colon={false} onSubmit={readOnly ? stopEvent : this.handleSubmit}>
              {!readOnly && (
                <Dropdown
                  overlay={
                    <Menu onClick={this.handleActionsMenuClick}>
                      <Menu.Item key="copyFromExistingRecord" disabled={Boolean(item.id)}>
                        {t('copyFromDashboardTemplate')}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button className="form-items-actions">
                    {t('actions')}
                    <Icon type="KeyboardArrowDown" />
                  </Button>
                </Dropdown>
              )}
              <div className="form-items-container">
                <ValidationError errors={this.state.validationErrors} />
                <Row>
                  <Col xs={24} lg={6}>
                    <Form.Item label={createLabel('name')}>
                      {createFieldDecorator('name', {
                        valuePropName: 'defaultValue',
                        trigger: 'onBlur',
                        validateTrigger: false,
                      })(<Input readOnly={readOnly} />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={18}>
                    <Form.Item label={createLabel('description')}>
                      {createFieldDecorator('description')(<Input readOnly={readOnly} />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={6}>
                    <Form.Item label={createLabel('accessType')}>
                      {createFieldDecorator('accessType')(
                        <Select readOnly={readOnly} allowClear={false}>
                          {Object.entries(chartAccessTypes).map(([key, value]) => (
                            <Option key={key} value={key}>
                              {t(value)}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={7}>
                    <Form.Item label={createLabel('createdDate')}>
                      <Input value={formatUserTime(item.createdDate, item.createdBy) || t('na')} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={7}>
                    <Form.Item label={createLabel('dataRefreshMinutes')}>
                      {createFieldDecorator('dataRefreshMinutes')(<InputNumber min={1} max={60} readOnly={readOnly} />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={4}>
                    <Form.Item label={HARD_SPACE}>
                      {createFieldDecorator('active', { valuePropName: 'checked' })(
                        <Checkbox readOnly={readOnly}>{createLabel('active')}</Checkbox>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mb-12">
                  <Col className="text-right">
                    <Tooltip title={t('addSection')} placement="topLeft">
                      <Button
                        size="small"
                        type="primary"
                        disabled={readOnly}
                        onClick={this.createHandleAddSectionClick()}
                      >
                        <Icon type="Add" bold />
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
                {(item?.sections ?? []).map((section, sectionIndex) => (
                  <Section
                    key={section.id}
                    extra={
                      <>
                        <Tooltip title={t('editSection')}>
                          <Button onClick={this.createHandleEditSectionClick(section)} type="link" size="small">
                            <Icon type="Edit" color={section.headerForegroundColor} />
                          </Button>
                        </Tooltip>
                        {!readOnly && (
                          <>
                            <Tooltip title={t('addChart')}>
                              <Button onClick={this.createHandleAddChartClick(section)} type="link" size="small">
                                <Icon type="Add" color={section.headerForegroundColor} bold />
                              </Button>
                            </Tooltip>
                            <Tooltip title={t('deleteSection')}>
                              <Button onClick={() => this.deleteSection(sectionIndex)} type="link" size="small">
                                <Icon type="Close" color={section.headerForegroundColor} />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </>
                    }
                    {...getSectionProps(section)}
                  >
                    <Row>
                      <Droppable
                        key={str(section.id)}
                        index={sectionIndex}
                        droppableId={JSON.stringify({ sectionId: section.id })}
                        direction="horizontal"
                        isDropDisabled={readOnly}
                      >
                        {(provided, snapshot) => (
                          <Line
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            readOnly={readOnly}
                            sectionHeight={section.height}
                          >
                            {(section?.charts ?? []).map((chart) => {
                              const chartWidth = getChartWidth(chart.widthType)
                              const focused = this.state.focusedId === chart.id
                              const setFocusId = (id) => this.setState({ focusedId: id })
                              const handleEdit = () =>
                                this.setState({
                                  chartDrawerVisible: true,
                                  chartDrawerTitle: chart.name,
                                  chartDrawerLinkTargetRecord: chart,
                                  chartDrawerParentRecord: section,
                                })

                              return (
                                <Draggable
                                  key={chart.id}
                                  draggableId={JSON.stringify({ chartId: chart.id })}
                                  index={chart.position}
                                  isDragDisabled={readOnly}
                                >
                                  {
                                    // eslint-disable-next-line no-shadow
                                    (provided, snapshot) => (
                                      <LineItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`ant-col ant-col-xs-24 ant-col-lg-${chartWidth}`}
                                      >
                                        <Form.Item
                                          onClick={() => setFocusId(chart.id)}
                                          onMouseEnter={() => setFocusId(chart.id)}
                                          onMouseLeave={() => setFocusId(null)}
                                          help={
                                            focused ? (
                                              <Actions>
                                                <Tooltip title={t('editChart')}>
                                                  <Button type="link" size="small" onClick={handleEdit}>
                                                    <Icon type="Edit" />
                                                  </Button>
                                                </Tooltip>
                                                {!readOnly && (
                                                  <>
                                                    <Popover
                                                      content={
                                                        <Settings
                                                          item={chart}
                                                          rootRecord={item}
                                                          onChange={(value) => this.changeChartSettings(chart, value)}
                                                        />
                                                      }
                                                      trigger={['click']}
                                                      placement="topLeft"
                                                    >
                                                      <Button type="link" size="small">
                                                        <Icon type="Settings" />
                                                      </Button>
                                                    </Popover>
                                                    <Tooltip title={t('duplicate')}>
                                                      <Button
                                                        type="link"
                                                        size="small"
                                                        onClick={() => this.duplicateChart(chart)}
                                                      >
                                                        <Icon type="ContentCopy" />
                                                      </Button>
                                                    </Tooltip>
                                                    <Tooltip title={t('deleteChart')}>
                                                      <Button
                                                        type="link"
                                                        size="small"
                                                        onClick={() => this.deleteChart(chart)}
                                                      >
                                                        <Icon type="Close" />
                                                      </Button>
                                                    </Tooltip>
                                                  </>
                                                )}
                                              </Actions>
                                            ) : undefined
                                          }
                                        >
                                          <Chart chart={chart} section={section} template={item} />
                                        </Form.Item>
                                      </LineItem>
                                    )
                                  }
                                </Draggable>
                              )
                            })}
                          </Line>
                        )}
                      </Droppable>
                    </Row>
                  </Section>
                ))}
              </div>
              <div className="form-buttons-container">
                <FormButtons
                  readOnly={readOnly}
                  onCancel={this.props.onCancel}
                  onSubmit={this.handleSubmit}
                  saveButtonLoading={this.state.saveButtonLoading}
                  saveAndCloseButtonLoading={this.state.saveAndCloseButtonLoading}
                />
              </div>
            </Form>
          </Container>
          {!readOnly && (
            <Drawer title={t('metrics')} getContainer={this.containerRef.current} visible sideDrawer>
              {isNil(primaryMetricCharts) ? (
                <Spin />
              ) : (
                <>
                  {' '}
                  <p>{t('selectMetricInfo')}</p>
                  <Form.Item>
                    <Row type="flex" justify="space-between" gutter={6}>
                      <Col className="flex-1">
                        <Select
                          value={this.state.primaryMetricType}
                          onChange={(value) => this.setPrimaryMetricType(value)}
                          placeholder={t('none')}
                          allowClear={false}
                          showSearch
                        >
                          {chartMetricCategoryTypes.map((category) => (
                            <OptGroup key={category.categoryType} label={category.categoryName}>
                              {chartMetricTypes
                                .filter((metric) => metric.categoryType === category.categoryType)
                                .map((metric) => (
                                  <Option key={metric.type} value={metric.type}>
                                    {metric.name}
                                  </Option>
                                ))}
                            </OptGroup>
                          ))}
                        </Select>
                      </Col>
                      <Col>
                        <Metrics
                          items={chartMetricTypes}
                          onSelect={(values) => this.setPrimaryMetricType(get(values, '[0].type'))}
                          filterProps={{ categoryTypes: chartMetricCategoryTypes }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  {!isEmpty(primaryMetricCharts) && (
                    <>
                      <SideHeader>{t('charts')}</SideHeader>
                      <p>{t('dragChartInfo')}</p>
                      {range(Math.ceil(primaryMetricCharts.length / 3)).map((row) => (
                        <div key={row}>
                          <Droppable
                            index={row}
                            droppableId={JSON.stringify({ row })}
                            direction="horizontal"
                            isDropDisabled
                          >
                            {(provided, snapshot) => (
                              <SideLine ref={provided.innerRef} {...provided.droppableProps}>
                                {[...Array(3).keys()].map((col) => {
                                  const chart = primaryMetricCharts[row * 3 + col]

                                  if (chart) {
                                    const chartType = chart.type
                                    const { iconType, iconRotation, languageKey } = chartTypes[chartType]

                                    return (
                                      <Draggable
                                        key={chartType}
                                        index={col}
                                        draggableId={JSON.stringify({ chartType })}
                                      >
                                        {
                                          // eslint-disable-next-line no-shadow
                                          (provided, snapshot) => (
                                            <>
                                              <SideLineItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                isDragging={snapshot.isDragging}
                                              >
                                                <Icon
                                                  type={iconType}
                                                  style={
                                                    iconRotation ? { transform: `rotate(${iconRotation}deg)` } : {}
                                                  }
                                                  size={28}
                                                />
                                                <div className="text-center">{t(languageKey)}</div>
                                              </SideLineItem>
                                              {snapshot.isDragging && (
                                                <SideLineItem>
                                                  <Icon
                                                    type={iconType}
                                                    style={
                                                      iconRotation ? { transform: `rotate(${iconRotation}deg)` } : {}
                                                    }
                                                    size={28}
                                                  />
                                                  <div className="text-center">{t(languageKey)}</div>
                                                </SideLineItem>
                                              )}
                                            </>
                                          )
                                        }
                                      </Draggable>
                                    )
                                  }

                                  return null
                                })}
                              </SideLine>
                            )}
                          </Droppable>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </Drawer>
          )}
        </DragDropContext>
        <Drawer
          title={this.state.sectionDrawerTitle}
          visible={this.state.sectionDrawerVisible}
          onClose={this.handleSectionDrawerClose}
          size="xs"
        >
          <SectionFormView
            parentRecord={item}
            linkTargetRecord={this.state.sectionDrawerLinkTargetRecord}
            onSave={() => {}}
            onSaveAndClose={this.handleSectionDrawerSaveAndClose}
            onCancel={this.handleSectionDrawerClose}
            readOnly={readOnly}
          />
        </Drawer>
        <Drawer
          title={this.state.chartDrawerTitle}
          visible={this.state.chartDrawerVisible}
          onClose={this.handleChartDrawerClose}
          size="md"
        >
          <ChartFormView
            rootRecord={item}
            parentRecord={this.state.chartDrawerParentRecord}
            linkTargetRecord={this.state.chartDrawerLinkTargetRecord}
            onSave={() => {}}
            onSaveAndClose={this.handleChartDrawerSaveAndClose}
            onCancel={this.handleChartDrawerClose}
            readOnly={readOnly}
          />
        </Drawer>
        <Modal
          title={t('selectDashboardTemplate')}
          visible={this.state.copyFromExistingVisible}
          okText={t('copy')}
          onOk={this.handleCopyFromExisting}
          okButtonProps={{
            loading: this.state.copyFromExistingLoading,
            disabled: !this.state.copyFromExistingSelectedRecord,
          }}
          onCancel={() =>
            this.setState({
              copyFromExistingVisible: false,
              copyFromExistingSelectedRecord: null,
            })
          }
          width={992}
        >
          <SelectDashboardTemplate
            onSelect={(values) => this.setState({ copyFromExistingSelectedRecord: values[0] })}
          />
        </Modal>
      </>
    )
  }
}

export default Form.create()(FormView)
