import axios from 'axios'
import produce from 'immer'
import {
  camelCase,
  cloneDeep,
  debounce,
  isArray,
  isEmpty,
  isNil,
  isPlainObject,
  isUndefined,
  toString as str,
  trim,
  omitBy,
  unset,
} from 'lodash'
import pluralize from 'pluralize'
import { getSessionItem, setSessionItem } from 'helpers/sessionStorage'
import { baseUrl as defaultBaseUrl } from 'config'
import entityNames from 'options/entityNames'
import { AUTH_SESSION_KEY, INACTIVITY_SESSION_KEY } from 'options/auth'
import { Emitter } from 'helpers/events'
import { DEFER_FORBIDDEN_ERROR_WITH, UPLOAD_PROGRESS, DOWNLOAD_PROGRESS } from 'options/events'

export const apiClient = axios.create()
apiClient.defaults.timeout = 0 // default is `0` (no timeout)
apiClient.interceptors.request.use(
  (config) => {
    config.onUploadProgress = (e) => Emitter.emit(UPLOAD_PROGRESS, e)
    config.onDownloadProgress = (e) => Emitter.emit(DOWNLOAD_PROGRESS, e)
    return config
  },
  (error) => Promise.reject(error)
)
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 && window.location.pathname !== '/login') {
      sessionStorage.clear()
      setSessionItem(INACTIVITY_SESSION_KEY, true)
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

let TransactionUUID = crypto.randomUUID()
const refreshTransactionUUID = debounce(() => {
  TransactionUUID = crypto.randomUUID()
}, 1000)

export const getConfig = ({ responseType = 'json', acceptMimeType = 'application/json' } = {}) => {
  const authenticationJwt = getSessionItem(AUTH_SESSION_KEY, {}).current?.authenticationJwt
  const Authorization = authenticationJwt ? `Bearer ${authenticationJwt}` : undefined

  refreshTransactionUUID()

  return {
    headers: {
      Authorization,
      TransactionUUID,
      MessageUUID: crypto.randomUUID(),
      ClientId: 'TRMSWeb',
      ClientTimestamp: Date.now().toString(),
      Accept: acceptMimeType,
    },
    responseType,
    timeout: 0, // default is `0` (no timeout)
  }
}

export const getEntityId = (entityName) => `${pluralize.singular(entityName)}Id`

const normalizeDtoFieldName = (dtoFieldName) => dtoFieldName.split('.').map(camelCase).join('.')

const normalizeListItems = ({ entityName, response, request, entityId = getEntityId(entityName) } = {}) =>
  produce(response, (draft) => {
    if (isArray(draft?.data?.items)) {
      const idField = entityName === entityNames.documents ? 'fileName' : 'id' // NOTE: Only Documents don't have 'id', even Users have 'id'
      const pageIndex = request?.pageIndex ?? 0
      const pageSize = request?.pageSize ?? 100

      draft.data.items = draft.data.items.map((item, index) => {
        if (isPlainObject(item)) {
          const id = item[idField] ?? pageIndex * pageSize + index + 1

          return {
            id,
            [entityId]: id,
            ...item,
          }
        }

        return item
      })
    }
  })

const normalizeFieldSettings = ({ entityName, response } = {}) =>
  produce(response, (draft) => {
    if (isArray(draft?.data?.fieldSettings)) {
      draft.data.fieldSettings = draft.data.fieldSettings
        .slice()
        .sort((a, b) => a.defaultColumnNumber - b.defaultColumnNumber)
        .map(
          ({
            alignment,
            columnHeadingIconName,
            columnHeadingLanguageKey,
            columnHeadingTooltipLanguageKey,
            columnWidth,
            defaultColumnNumber,
            displayByDefault,
            displayFormat,
            dtoFieldName,
            isDisplayable,
            isRequired,
            linkTarget,
            linkTargetIsReadOnly,
            maxLength,
            recordInfoLanguageKey,
            recordLabelLanguageKey,
            searchEnumValue,
            sortByEnumValue,
            totalCalculationType,
            ...rest
          }) => {
            if (dtoFieldName.match(/\s/)) {
              console.error(`Invalid dtoFieldName used in '${entityName}': ${dtoFieldName}`)
            }

            if (dtoFieldName.match(/\./)) {
              console.warn(`Nested dtoFieldName used in '${entityName}': ${dtoFieldName}`)
            }

            return {
              dtoFieldName: normalizeDtoFieldName(dtoFieldName),
              columnHeadingLanguageKey,
              recordLabelLanguageKey,
              linkTarget: camelCase(linkTarget),
              linkTargetIsReadOnly,
              columnHeadingTooltipLanguageKey,
              columnHeadingIconName,
              columnWidth,
              alignment,
              defaultColumnNumber,
              displayByDefault,
              displayFormat,
              isDisplayable,
              isRequired,
              maxLength,
              recordInfoLanguageKey,
              searchEnumValue,
              sortByEnumValue,
              totalCalculationType,
              ...rest,
            }
          }
        )
    }
  })

const normalizePageTotals = ({ entityName, response } = {}) =>
  produce(response, (draft) => {
    if (isArray(draft?.data?.pageTotals)) {
      draft.data.pageTotals = draft.data.pageTotals.reduce(
        (acc, each) => ({ ...acc, [normalizeDtoFieldName(each.dtoFieldName)]: each.value }),
        {}
      )
    }
  })

export const ignoreForbiddenWith = (defaultValue) => (error) => {
  if (error?.response?.status === 403) {
    return defaultValue
  }

  throw error
}

export const deferForbiddenWith = (defaultValue) => (error) => {
  if (error?.response?.status === 403) {
    Emitter.emit(DEFER_FORBIDDEN_ERROR_WITH, { error })

    return defaultValue
  }

  throw error
}

export const stringifyOptions = (options) => {
  if (isPlainObject(options)) {
    const queryString = Object.entries({ ...options })
      .filter(([, value]) => str(value))
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(str(value))}`)
      .join('&')

    return queryString ? `?${queryString}` : ''
  }

  return str(options)
}

const sanitizeItem = (item) => (isPlainObject(item) ? omitBy(item, isUndefined) : item)

export const sanitizeUrl = (url = '') => {
  let sanitized = url

  while (sanitized.match(/([^:]\/)\/+/g)) {
    sanitized = sanitized.replace(/([^:]\/)\/+/g, '$1') // No double slash other than the protocol
  }

  while (sanitized.match(/\?\?/g)) {
    sanitized = sanitized.replace(/\?\?/g, '?') // No double question marks
  }

  while (sanitized.match(/\/\?/g)) {
    sanitized = sanitized.replace(/\/\?/g, '?') // No slash with question mark
  }

  return trim(sanitized, '/?&') // No dangling slash, ampersand, and question marks
}

const sanitizeFilter = (filterDto) => {
  if (!isPlainObject(filterDto)) {
    return filterDto
  }

  const params = cloneDeep(filterDto)

  if (isEmpty(params.sortByField)) {
    unset(params, 'sortByField')
    unset(params, 'sortBy')
  }

  if (isEmpty(params.sortOrder)) {
    unset(params, 'sortOrder')
  }

  if (isEmpty(params.search)) {
    unset(params, 'search')
    unset(params, 'searchType')
    unset(params, 'searchFieldEnumValues')
    unset(params, 'searchFields')
  } else {
    params.search = params.search.slice(0, 200)
    params.searchType = params.searchType || 'AllWords'
  }

  unset(params, 'users')
  unset(params, 'locations')
  unset(params, 'operators')
  unset(params, 'formTemplates')
  unset(params, 'assetLocations')
  unset(params, 'inventoryLocations')

  return omitBy(params, isUndefined)
}

export const createPost =
  (
    entityName,
    {
      action = '',
      basePath = 'api',
      baseUrl = defaultBaseUrl,
      responseType,
      acceptMimeType,
      entityUrl = entityName,
      entityId,
      path = `${baseUrl}/${basePath}/${entityUrl}`,
    } = {}
  ) =>
  (params, options) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    const url = sanitizeUrl(`${path}/${action}${stringifyOptions(options)}`)
    const group = `POST ${url}`
    const request = ['list', 'selectionList'].includes(action) ? sanitizeFilter(params) : sanitizeItem(params)

    return apiClient
      .post(url, request, getConfig({ responseType, acceptMimeType }))
      .then((response) => normalizeListItems({ entityName, response, request, entityId }))
      .then((response) => normalizePageTotals({ entityName, response }))
      .catch((error) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.error(error)
        console.groupEnd()
        throw error
      })
      .then((response) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.log('Results:', response)
        console.groupEnd()
        return response
      })
  }

export const createChildPost =
  (
    entityName,
    childName,
    { action = '', basePath = 'api', baseUrl = defaultBaseUrl, responseType, acceptMimeType } = {}
  ) =>
  (parentId, params) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    if (isEmpty(childName)) {
      throw new Error('childName is empty')
    }

    if (isNil(parentId)) {
      throw new Error('parentId is undefined')
    }

    const url = sanitizeUrl(`${baseUrl}/${basePath}/${entityName}/${parentId}/${childName}/${action}`)
    const request = ['list', 'selectionList'].includes(action) ? sanitizeFilter(params) : sanitizeItem(params)
    const group = `POST ${url}`

    return apiClient
      .post(url, request, getConfig({ responseType, acceptMimeType }))
      .then((response) => normalizeListItems({ entityName: childName, response, request }))
      .then((response) => normalizePageTotals({ entityName, response }))
      .then((response) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.log('Results:', response)
        console.groupEnd()
        return response
      })
      .catch((error) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.error(error)
        console.groupEnd()
        throw error
      })
  }

export const createOrphanedChildPost =
  (
    entityName,
    childName,
    { action = '', basePath = 'api', baseUrl = defaultBaseUrl, responseType, acceptMimeType } = {}
  ) =>
  (params) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    if (isEmpty(childName)) {
      throw new Error('childName is empty')
    }

    const url = sanitizeUrl(`${baseUrl}/${basePath}/${entityName}/${childName}/${action}`)
    const request = ['list', 'selectionList'].includes(action) ? sanitizeFilter(params) : sanitizeItem(params)
    const group = `POST ${url}`

    return apiClient
      .post(url, request, getConfig({ responseType, acceptMimeType }))
      .then((response) => normalizeListItems({ entityName: childName, response, request }))
      .then((response) => normalizePageTotals({ entityName, response }))
      .then((response) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.log('Results:', response)
        console.groupEnd()
        return response
      })
      .catch((error) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.error(error)
        console.groupEnd()
        throw error
      })
  }

export const createPut =
  (entityName, { basePath = 'api', baseUrl = defaultBaseUrl, entityUrl = entityName, idField = 'id' } = {}) =>
  (params, options) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    const id = encodeURIComponent(params[idField])
    const url = sanitizeUrl(`${baseUrl}/${basePath}/${entityUrl}/${id}${stringifyOptions(options)}`)
    const request = sanitizeItem(params)
    const group = `PUT ${url}`

    return apiClient
      .put(url, request, getConfig())
      .then((response) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.log('Results:', response)
        console.groupEnd()
        return response
      })
      .catch((error) => {
        console.groupCollapsed(group)
        console.log('Request:', request)
        console.error(error)
        console.groupEnd()
        throw error
      })
  }

export const createGet =
  (entityName, { action = '', basePath = 'api', baseUrl = defaultBaseUrl, entityUrl = entityName } = {}) =>
  (params) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    const url = sanitizeUrl(`${baseUrl}/${basePath}/${entityUrl}/${action}/${stringifyOptions(params)}`)
    const group = `GET ${url}`

    return apiClient
      .get(url, getConfig())
      .then((response) => normalizeFieldSettings({ entityName, response }))
      .then((response) => {
        console.groupCollapsed(group)
        console.log('Results:', response)
        console.groupEnd()
        return response
      })
      .catch((error) => {
        console.groupCollapsed(group)
        console.error(error)
        console.groupEnd()
        throw error
      })
  }

export const createDelete =
  (entityName, { basePath = 'api', baseUrl = defaultBaseUrl, entityUrl = entityName } = {}) =>
  (params) => {
    if (isEmpty(entityName)) {
      throw new Error('entityName is empty')
    }

    const url = sanitizeUrl(`${baseUrl}/${basePath}/${entityUrl}/${stringifyOptions(params)}`)
    const group = `DELETE ${url}`

    return apiClient
      .delete(url, getConfig())
      .then((response) => {
        console.groupCollapsed(group)
        console.log('Results:', response)
        console.groupEnd()
        return response
      })
      .catch((error) => {
        console.groupCollapsed(group)
        console.error(error)
        console.groupEnd()
        throw error
      })
  }
