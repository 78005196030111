import * as React from 'react'
import produce from 'immer'
import styled from 'styled-components'
import { Form, Input, Spin, Button, Tabs, Dropdown, Menu, Badge, message } from 'antd'
import { set, get, isEmpty, debounce, cloneDeep, isEqual, pick, isNil } from 'lodash'
import { setStorageItem, getStorageItem } from 'helpers/localStorage'
import { showError, showClientNotifications, showValidationError, ValidationError } from 'helpers/errors'
import { createResetState, DEBOUNCE } from 'helpers/utils'
import {
  createLabelFactory,
  createFieldFactory,
  createSaveDocumentItems,
  getChangedItems,
  isReadOnly,
  validateMultipleEmails,
} from 'helpers/formViews'
import { Emitter, stopEvent } from 'helpers/events'
import { SIDE_FORM_ADD_FROM_MODAL_CLOSE } from 'options/events'
import { formatUserTime } from 'helpers/dateTime'
import { t, toLocaleCurrency } from 'helpers/i18n'
import { allowPricing } from 'helpers/auth'
import { createGeneratePurchaseOrderNumbers, BadgeContainer } from 'helpers/procurement'
import requisitionerFields from 'options/requisitionerFields'
import Documents from 'containers/Documents/ChildListView'
import OrderItems from 'containers/Issue/Items'
import Help from 'elements/Help'
import SideForm from 'containers/Issue/FormView/SideForm'
import OrderSuppliers from 'containers/Orders/Suppliers'
import Select, { Option, getOptionProps } from 'elements/Select'
import Modal from 'elements/Modal'
import Checkbox from 'elements/Checkbox'
import Comments from 'elements/Comments'
import Icon from 'elements/Icon'
import { Row, Col } from 'elements/Grid'
import ValidateStatus from 'containers/Issue/FormView/ValidateStatus'

const getStorageKey = () => 'inventory.issue.formView'

const Header = styled(Row)`
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 24px;

  h2 {
    margin: 0;
    font-size: 16px;
    line-height: 2;
  }
`

class FormView extends React.Component {
  state = {}

  constructor(props) {
    super(props)

    this.saveDocumentItems = createSaveDocumentItems(this, 'Order')
    this.saveIssue = debounce(this.saveIssue, DEBOUNCE)
    this.refetchItem = debounce(this.refetchItem, DEBOUNCE)
    this.saveOrderItems = debounce(this.saveOrderItems, DEBOUNCE)
    this.generatePurchaseOrderNumbers = createGeneratePurchaseOrderNumbers(this)
    this.resetState = createResetState(
      this,
      { tabsActiveKey: 'items', requireQuantity: true },
      getStorageItem(getStorageKey(), {})
    )
  }

  async componentDidMount() {
    await this.resetState()
    await this.fetchItem()
  }

  beforeValidateFields = () => {
    requisitionerFields.forEach(({ key }) =>
      this.props.form.setFields({ [key]: { value: this.state.item[key], errors: null } })
    )
  }

  promiseState = (state = {}) => new Promise((resolve) => this.setState(state, resolve))

  saveState = () => setStorageItem(getStorageKey(), pick(this.state, ['quickscan', 'requireQuantity']))

  getItemId = () => [this.state.item?.id, this.props.linkTargetRecord?.orderId].find(Boolean)

  fetchItem = async ({ itemId = this.getItemId() } = {}) => {
    try {
      const responses = await Promise.all([
        this.props.getItem(itemId).catch((error) => {
          this.props.onCancel?.()
          throw error
        }),
        this.props.getSettings({ type: this.props.settingsType }),
      ])

      const item = cloneDeep(responses[0].value.data)

      const [documentItems, billToAddressList, shipToAddressList, orderItems, orderSupplierItems] = await Promise.all([
        item.id
          ? this.props.getDocumentItems({
              request: {
                domainObjectId: item.id,
                domainObjectType: 'Order',
                documentType: 'ObjectDocument',
              },
            })
          : Promise.resolve(null),
        this.props.getAddressList({ type: 'billto' }),
        this.props.getAddressList({ type: 'shipto' }),
        item.id ? this.props.getOrderItems(item.id, { includeInventoryStatus: true }) : Promise.resolve(null),
        item.id ? this.props.getOrderSupplierItems(item.id) : Promise.resolve(null),
      ])

      const populatedOrderItems = get(orderItems, 'value.data.items', []).map((each) => ({
        ...each,
        populated: true,
        timestamp: new Date().toJSON(),
      }))

      this.setState(
        {
          item: cloneDeep(item),
          itemOriginal: cloneDeep(item),
          fieldSettings: responses[1].value.data.fieldSettings,
          documentItems: get(documentItems, 'value.data.items', []),
          documentItemsOriginal: get(documentItems, 'value.data.items', []),
          billToAddressList: get(billToAddressList, 'value.data.items', []),
          shipToAddressList: get(shipToAddressList, 'value.data.items', []),
          orderItems: cloneDeep(populatedOrderItems),
          orderItemsOriginal: cloneDeep(populatedOrderItems),
          orderSupplierItems: get(orderSupplierItems, 'value.data.items', []),
          orderSupplierItemsOriginal: get(orderSupplierItems, 'value.data.items', []),
          orderSupplierItemsTotals: get(orderSupplierItems, 'value.data.pageTotals', {}),
          formHasChanged: false,
        },
        this.generatePurchaseOrderNumbers
      )
    } catch (error) {
      showError({ error })
    }
  }

  setItemValue = (name, value) =>
    this.setState(
      produce((draft) => {
        set(draft.item, name, value)

        if (name === 'shipToAddressId') {
          draft.item.taxRate = draft.shipToAddressList.find((one) => one.id === value).taxRate / 100
        }

        this.props.form.setFieldsValue({ [name]: value })
      }),
      this.saveIssue
    )

  saveIssue = () =>
    this.props.form.validateFields((errors, values) => {
      this.setState({ validationErrors: errors }, async () => {
        if (!isEmpty(errors)) {
          showValidationError()
          return
        }

        try {
          const { item } = this.state

          await this.props.updateItem(item)

          await this.refetchItem()
        } catch (error) {
          showError({ error })
        }
      })
    })

  refetchItem = async (itemId = this.getItemId()) => {
    try {
      const [updated, orderItems, orderSupplierItems] = await Promise.all([
        this.props.getItem(itemId).catch((error) => {
          this.props.onCancel?.()
          throw error
        }),
        this.props.getOrderItems(itemId, { includeInventoryStatus: true }),
        this.props.getOrderSupplierItems(itemId),
      ])

      const item = cloneDeep(get(updated, 'value.data', {}))

      const populatedOrderItems = get(orderItems, 'value.data.items', []).map((each) => ({
        ...each,
        populated: true,
        timestamp: new Date().toJSON(),
      }))

      this.setState(
        {
          item: cloneDeep(item),
          itemOriginal: cloneDeep(item),
          orderItems: cloneDeep(populatedOrderItems),
          orderItemsOriginal: cloneDeep(populatedOrderItems),
          orderItemsTotals: get(orderItems, 'value.data.pageTotals', {}),
          orderSupplierItems: get(orderSupplierItems, 'value.data.items', []),
          orderSupplierItemsOriginal: get(orderSupplierItems, 'value.data.items', []),
          orderSupplierItemsTotals: get(orderSupplierItems, 'value.data.pageTotals', {}),
        },
        this.generatePurchaseOrderNumbers
      )
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ issueItemsLoading: false })
    }
  }

  handleSideFormSubmit = async (sideFormState) => {
    const { item } = this.state
    const {
      assetDisplayName = '',
      jobDisplayName = '',
      locationDisplayName = '',
      operatorDisplayName = '',
      ...params
    } = cloneDeep(sideFormState)

    try {
      this.setState({ sideFormLoading: true, tabsActiveKey: 'items', formButtonsDisabled: true })

      const response = await this.props.issueOrderItem(item.id, {
        assetDisplayName,
        jobDisplayName,
        locationDisplayName,
        operatorDisplayName,
        quantityIssued: params.quantity || 1,
        barcode: params.barcode,
        assetId: params.asset?.id,
        assetName: params.asset?.name ?? `*${assetDisplayName}`,
        assetNumber: params.asset?.number,
        assetBarcode: params.asset?.barcode ?? params.assetBarcode,
        jobId: params.job?.id,
        jobName: params.job?.name ?? `*${jobDisplayName}`,
        jobNumber: params.job?.number,
        jobBarcode:
          params.job?.barcode ?? (!this.props.customer.tagSettings.tag1Restricted ? jobDisplayName : undefined),
        locationId: params.location?.id,
        locationName: params.location?.name ?? `*${locationDisplayName}`,
        locationNumber: params.location?.number,
        locationBarcode: params.location.barcode,
        operatorId: params.operator?.id,
        operatorName: params.operator?.name ?? `*${operatorDisplayName}`,
        operatorNumber: params.operator?.number,
        operatorBarcode: params.operator?.barcode ?? params.operatorBarcode,
        tagA: params.tagA,
        tagB: params.tagB,
        tagC: params.tagC,
        tagD: params.tagD,
        tagE: params.tagE,
        userName: this.props.user.userName,
        sourceCatalogName: params.sourceCatalogName,
      })

      this.setState({ sideFormLoading: false })

      showClientNotifications({ response })

      if (response.value.data.failureCount > 0) {
        throw new Error()
      }

      await this.refetchItem()
    } catch (error) {
      showError({ error })

      this.setState({ sideFormLoading: false })

      return false
    } finally {
      this.setState({ formButtonsDisabled: false })
    }

    return true
  }

  handleDocumentsChange = async (values) => {
    try {
      await this.promiseState({ documentItems: values })
      await this.saveDocumentItems(this.state.item.id)

      const documentItems = await this.props.getDocumentItems({
        request: {
          domainObjectId: this.state.item.id,
          domainObjectType: 'Order',
          documentType: 'ObjectDocument',
        },
      })

      this.setState({
        documentItems: get(documentItems, 'value.data.items', []),
        documentItemsOriginal: get(documentItems, 'value.data.items', []),
      })
    } catch (error) {
      showError({ error })
    }
  }

  saveOrderItems = async (orderItems = this.state.orderItems) => {
    let failureCount = 0

    try {
      this.setState({ issueItemsLoading: true })

      const { item, orderItemsOriginal } = this.state
      const { updating, deleting } = getChangedItems(orderItemsOriginal, orderItems)
      const responses = await Promise.all(updating.map((each) => this.props.issueOrderItem(item.id, each)))

      failureCount += responses.reduce((acc, each) => acc + each.value.data.failureCount, 0)

      for (const response of responses) {
        showClientNotifications({ response })

        if (response.value.data.failureCount > 0) {
          throw new Error()
        }
      }

      if (!isEmpty(deleting)) {
        try {
          const response = await this.props.deleteOrderItems(
            item.id,
            deleting.map((each) => each.id)
          )

          failureCount += response.value.data.failureCount

          showClientNotifications({ response })
        } catch (error) {
          showError({ error })
        }
      }
    } catch (error) {
      failureCount++
      showError({ error })
    } finally {
      this.refetchItem()
    }

    return failureCount
  }

  saveOrderSupplierItems = async (orderId) => {
    let failureCount = 0

    try {
      const { updating } = getChangedItems(this.state.orderSupplierItemsOriginal, this.state.orderSupplierItems)

      if (!isEmpty(updating)) {
        const response = await this.props.updateOrderSupplierItems(
          orderId,
          updating.map((each) => ({ ...each, orderId }))
        )

        failureCount += response.value.data.failureCount

        showClientNotifications({ response })
      }
    } catch (error) {
      failureCount++
      showError({ error })
    }

    return failureCount
  }

  handleOrderItemsChange = async (values) => {
    let failureCount = 0

    await this.promiseState({ orderItems: values })
    failureCount += await this.saveOrderItems(values)

    if (failureCount > 0) {
      throw new Error()
    }
  }

  handleOrderSuppliersItemsChange = async (values) => {
    let failureCount = 0

    try {
      await this.promiseState({ orderSupplierItems: values })
      failureCount += await this.saveOrderSupplierItems(this.state.item.id)
      await this.generatePurchaseOrderNumbers()
      await this.refetchItem()
    } catch (error) {
      failureCount++
      showError({ error })
    }

    if (failureCount > 0) {
      throw new Error()
    }
  }

  handleAddFromList = async (sideFormState) => {
    const parentId = this.state.item.id
    const {
      assetDisplayName = '',
      jobDisplayName = '',
      locationDisplayName = '',
      operatorDisplayName = '',
      ...params
    } = cloneDeep(sideFormState)

    const asset = (params?.assets?.items ?? []).find((one) => one.id === params.assetId)
    const job = (params?.jobs?.items ?? []).find((one) => one.id === params.jobId)
    const location = (params?.locations?.items ?? []).find((one) => one.id === params.locationId)
    const operator = (params?.operators?.items ?? []).find((one) => one.id === params.operatorId)

    this.setState({ addFromListLoading: true })

    try {
      const response = await this.props.validateIssueItems(
        parentId,
        params.addFromListItems.map((each) => ({
          quantityIssued: each.quantity || 1,
          barcode: each.barcode,
          assetId: asset?.id,
          assetName: asset?.name ?? `*${assetDisplayName}`,
          assetBarcode: asset?.barcode,
          jobId: job?.id,
          jobName: job?.name ?? `*${jobDisplayName}`,
          jobNumber: job?.number,
          jobBarcode: job?.barcode ?? jobDisplayName,
          locationId: location?.id,
          locationName: location?.name ?? `*${locationDisplayName}`,
          locationBarcode: location?.barcode,
          operatorId: operator?.id,
          operatorName: operator?.name ?? `*${operatorDisplayName}`,
          operatorNumber: operator?.number,
          operatorBarcode: operator?.barcode,
          tagA: params.tagA,
          tagB: params.tagB,
          tagC: params.tagC,
          tagD: params.tagD,
          tagE: params.tagE,
          userName: this.props.user.userName,
          sourceCatalogName: params.sourceCatalogName,
        }))
      )

      const seed = Date.now() * -1

      this.setState({
        validateIssueStatusVisible: true,
        validateIssueStatusItems: response.value.data.map((each, index) => ({ ...each, id: seed - index })),
        validateIssueStatusSelectedItems: [],
        validateIssueStatusLoading: false,
      })

      Emitter.emit(SIDE_FORM_ADD_FROM_MODAL_CLOSE)
    } catch (error) {
      showError({ error })

      return false
    } finally {
      this.setState({ addFromListLoading: false })
    }

    return true
  }

  handleSubmitOrderClick = () => {
    Modal.confirm({
      autoFocusButton: 'ok',
      title: t('confirmSubmitIssuedItemsTitle'),
      content: t('confirmSubmitIssuedItemsDescription'),
      okText: t('submit'),
      okType: 'primary',
      cancelText: t('cancel'),
      onOk: async () => {
        const { item } = this.state

        if (
          this.props.customer.generalSettings.requireRequisitionerOnOrder &&
          requisitionerFields.some(({ key }) => isEmpty(item[key])) &&
          this.state.orderItems.find((one) => one.quantityOrdered > 0)
        ) {
          this.setState({ validationErrors: true, tabsActiveKey: 'order' }, () => {
            showValidationError()
            requisitionerFields.forEach(({ key }) => {
              if (isEmpty(item[key])) {
                this.props.form.setFields({
                  [key]: {
                    value: item[key],
                    errors: [new Error(t('requiredField'))],
                  },
                })
              }
            })
          })
        } else {
          try {
            this.setState({ actionButtonLoading: true })

            const response = await this.props.submitItem(item.id)

            showClientNotifications({ response })

            if (response.value.data.failureCount > 0) {
              throw new Error()
            }

            await this.props.onSubmitItem?.()
          } catch (error) {
            showError({ error })
          } finally {
            this.setState({ actionButtonLoading: false })
          }
        }

        this.setState({ actionButtonLoading: false })
      },
    })
  }

  handleValidateIssueStatusSubmit = async () => {
    try {
      const { item } = this.state

      this.setState({ validateIssueStatusLoading: true })

      for (const orderItem of this.state.validateIssueStatusSelectedItems) {
        try {
          const { id, ...params } = orderItem
          const response = await this.props.issueOrderItem(item.id, params)

          showClientNotifications({ response })

          if (response.value.data.failureCount > 0) {
            throw new Error()
          }
        } catch (error) {
          showError({ error })
        }
      }

      await this.refetchItem()

      this.setState({ validateIssueStatusVisible: false })
    } catch (error) {
      showError({ error })
    } finally {
      this.setState({ validateIssueStatusLoading: false })
    }
  }

  handleActionsMenuClick = ({ key }) => {
    switch (key) {
      case 'deleteIssue':
        Modal.confirm({
          autoFocusButton: 'ok',
          title: t('confirmDeleteOrder'),
          content: t('confirmDeleteOrderDescription'),
          okText: t('delete'),
          okType: 'danger',
          cancelText: t('cancel'),
          onOk: async () => {
            try {
              const response = await this.props.deleteItem(this.state.item.id)

              showClientNotifications({ response })

              if (response.value.data.failureCount > 0) {
                throw new Error()
              }

              await this.props.onSubmitItem()
            } catch (error) {
              showError({ error })
            }
          },
        })
        break

      default:
        message.warn(t('underDevelopment'))
        break
    }
  }

  hasUnsavedChanges = () =>
    this.state.formHasChanged ||
    !this.state.item?.id ||
    !isEqual(this.state.item, this.state.itemOriginal) ||
    !isEqual(this.state.orderItems, this.state.orderItemsOriginal) ||
    !isEqual(this.state.orderSupplierItems, this.state.orderSupplierItemsOriginal) ||
    !isEqual(this.state.documentItems, this.state.documentItemsOriginal)

  render() {
    const { item, fieldSettings } = this.state

    if (!item || !fieldSettings) {
      return (
        <div style={{ padding: '16px 24px' }}>
          <Spin />
        </div>
      )
    }

    const createLabel = createLabelFactory(fieldSettings)
    const createFieldDecorator = createFieldFactory(this.props.form, item, fieldSettings)
    const readOnly = isReadOnly(this)
    const showSubmitOrderButton = !isEmpty(this.state.orderItems)

    const extraButtonsDisabled =
      this.state.formButtonsDisabled || this.state.saveButtonLoading || this.state.saveAndCloseButtonLoading

    return (
      <>
        <div style={{ minWidth: '512px' }}>
          <Row type="flex" className="flex-no-wrap">
            <Col
              sm={12}
              lg={8}
              xl={6}
              style={{ backgroundColor: 'rgb(247, 247, 247)', borderRight: '1px solid rgba(0, 0, 0, 0.15)' }}
            >
              <Header>
                <Col xs={12} className="whitespace-nowrap">
                  <h2>{t('issueItem')}</h2>
                </Col>
                <Col xs={12} className="text-right">
                  <BadgeContainer>
                    <Badge {...(this.state.quickscan ? { count: '!' } : {})}>
                      <Button onClick={() => this.setState({ showDataEntrySettings: true })}>
                        <Icon type="Settings" />
                      </Button>
                    </Badge>
                  </BadgeContainer>
                </Col>
              </Header>
              <SideForm
                quickscan={this.state.quickscan}
                requireQuantity={this.state.requireQuantity}
                onSubmitting={(pending) => this.setState({ formButtonsDisabled: pending, issueItemsLoading: pending })}
                onSubmit={this.handleSideFormSubmit}
                loading={this.state.sideFormLoading}
                onAddFromList={this.handleAddFromList}
                addFromListLoading={this.state.addFromListLoading}
                parentRecord={{
                  fieldSettings,
                  ...item,
                  ...pick(this.state, ['orderItems', 'orderSupplierItems', 'documentItems', 'purchaseOrderNumbers']),
                }}
                domainObjectType="None"
                disabled={extraButtonsDisabled}
              />
            </Col>
            <Col sm={12} lg={16} xl={18} style={{ position: 'relative' }}>
              <Header>
                <Col xs={12} className="whitespace-nowrap">
                  <h2>{t('draftOrder')}</h2>
                </Col>
                <Col xs={12} className="text-right">
                  <Dropdown
                    overlay={
                      <Menu onClick={this.handleActionsMenuClick}>
                        <Menu.Item key="deleteIssue">{t('delete')}</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <Button>
                      {t('actions')}
                      <Icon type="KeyboardArrowDown" />
                    </Button>
                  </Dropdown>
                </Col>
              </Header>
              <Form layout="vertical" colon={false} onSubmit={stopEvent}>
                <div className="form-items-container">
                  <ValidationError errors={this.state.validationErrors} />
                  <Row>
                    <Col xs={24} lg={6}>
                      <Form.Item label={t('orderId')}>
                        <Input value={item.id} disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Form.Item label={createLabel('createDate')}>
                        <Input value={formatUserTime(item.createDate, item.userName) || t('na')} disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Form.Item label={createLabel('statusName')}>
                        <Input value={item.statusName} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Tabs
                    activeKey={this.state.tabsActiveKey}
                    onChange={(tabsActiveKey) => this.setState({ tabsActiveKey })}
                    defaultActiveKey="items"
                  >
                    <Tabs.TabPane key="order" tab={t('order')} forceRender>
                      <Row>
                        <Col xs={24} lg={18}>
                          <Form.Item label={createLabel('billToAddressId')}>
                            {createFieldDecorator('billToAddressId')(
                              <Select
                                onChange={(value) => this.setItemValue('billToAddressId', value)}
                                placeholder={t('select')}
                                allowClear={false}
                                readOnly={readOnly}
                              >
                                {(this.state.billToAddressList ?? []).map((each) => (
                                  <Option key={each.id} value={each.id}>
                                    <span {...getOptionProps(each)}>{each.displayName}</span>
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                          <Form.Item label={createLabel('shipToAddressId')}>
                            {createFieldDecorator('shipToAddressId')(
                              <Select
                                onChange={(value) => this.setItemValue('shipToAddressId', value)}
                                placeholder={t('select')}
                                allowClear={false}
                                readOnly={readOnly}
                              >
                                {(this.state.shipToAddressList ?? []).map((each) => (
                                  <Option key={each.id} value={each.id}>
                                    <span {...getOptionProps(each)}>{each.displayName}</span>
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                          <Row>
                            <Col xs={24} lg={12}>
                              {requisitionerFields.map(({ key, type }) => (
                                <Form.Item
                                  key={key}
                                  label={
                                    key === 'requisitionerEmail' ? (
                                      <span>
                                        {createLabel(key)} <Help title={t('multipleEmailsHelp')} />
                                      </span>
                                    ) : (
                                      createLabel(key)
                                    )
                                  }
                                  required={
                                    this.props.customer.generalSettings.requireRequisitionerOnOrder &&
                                    this.state.orderItems.find((one) => one.quantityOrdered > 0)
                                  }
                                >
                                  {createFieldDecorator(
                                    key,
                                    key === 'requisitionerEmail'
                                      ? { extraRules: [validateMultipleEmails()] }
                                      : undefined
                                  )(
                                    <Input
                                      type={type}
                                      onChange={(e) => this.setItemValue(key, e.target.value)}
                                      readOnly={readOnly}
                                    />
                                  )}
                                </Form.Item>
                              ))}
                            </Col>
                            <Col xs={24} lg={12}>
                              <Form.Item label={createLabel('accountCode')}>
                                {createFieldDecorator('accountCode')(
                                  <Input
                                    onChange={(e) => this.setItemValue('accountCode', e.target.value)}
                                    readOnly={readOnly}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Comments
                            value={item.comment}
                            onChange={(value) => this.setItemValue('comment', value)}
                            rows={7}
                            label={createLabel('comment')}
                            title={t('addComment')}
                            readOnly={readOnly}
                          />
                        </Col>
                        {allowPricing() && (
                          <Col xs={24} lg={6}>
                            {[
                              ...(this.props.customer.moduleSettings.enableTax
                                ? ['exemptTotal', 'taxableTotal', 'taxTotal']
                                : []),
                              'subTotal',
                              'adjustmentsTotal',
                              'freightTotal',
                              'orderTotal',
                            ].map((each) => (
                              <Form.Item
                                key={each}
                                label={
                                  each === 'taxTotal' ? (
                                    <span>
                                      {createLabel(each)}{' '}
                                      {`(${item.taxRate.toLocaleString(this.props.locale, {
                                        style: 'percent',
                                        maximumFractionDigits: 2,
                                      })})`}
                                    </span>
                                  ) : (
                                    createLabel(each)
                                  )
                                }
                              >
                                <Input className="text-right" value={toLocaleCurrency(item[each])} disabled />
                              </Form.Item>
                            ))}
                          </Col>
                        )}
                      </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="items" tab={t('itemsTab')} forceRender>
                      <OrderItems
                        items={this.state.orderItems ?? []}
                        pageTotals={this.state.orderItemsTotals ?? {}}
                        onChange={this.handleOrderItemsChange}
                        settingsType={this.props.settingsType}
                        parentRecord={{
                          fieldSettings,
                          ...item,
                          ...pick(this.state, [
                            'orderItems',
                            'orderSupplierItems',
                            'documentItems',
                            'purchaseOrderNumbers',
                          ]),
                        }}
                        loading={this.state.issueItemsLoading}
                        savingIsDeferred={false}
                        parentHasUnsavedChanges={this.hasUnsavedChanges()}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="suppliers" tab={t('suppliers')} forceRender>
                      <OrderSuppliers
                        items={this.state.orderSupplierItems ?? []}
                        pageTotals={this.state.orderSupplierItemsTotals ?? {}}
                        onChange={this.handleOrderSuppliersItemsChange}
                        settingsType={this.props.settingsType}
                        parentRecord={{
                          fieldSettings,
                          ...item,
                          ...pick(this.state, [
                            'orderItems',
                            'orderSupplierItems',
                            'documentItems',
                            'purchaseOrderNumbers',
                          ]),
                        }}
                        readOnly={readOnly}
                        savingIsDeferred={false}
                        parentHasUnsavedChanges={this.hasUnsavedChanges()}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="documents" tab={t('documents')} forceRender>
                      <Documents
                        domainObjectId={item.id}
                        domainObjectType="Order"
                        items={this.state.documentItems ?? []}
                        onChange={this.handleDocumentsChange}
                        readOnly={readOnly}
                        savingIsDeferred={false}
                      />
                    </Tabs.TabPane>
                  </Tabs>
                </div>
                <div className="form-buttons-container">
                  {showSubmitOrderButton && (
                    <div className="float-left text-left">
                      <Button
                        type="primary"
                        onClick={this.handleSubmitOrderClick}
                        loading={this.state.actionButtonLoading}
                        disabled={this.hasUnsavedChanges() || extraButtonsDisabled}
                      >
                        {t('submit')}
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </div>
        <Modal
          title={t('dataEntrySettings')}
          visible={this.state.showDataEntrySettings}
          onCancel={() => this.setState({ showDataEntrySettings: false })}
          footer={null}
        >
          <Form.Item>
            <Checkbox
              checked={this.state.quickscan}
              onChange={(e) => this.setState({ quickscan: e.target.checked }, this.saveState)}
            >
              {t('quickscan')} <Help title={t('quickScanInfo')} />
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={this.state.requireQuantity}
              onChange={(e) => this.setState({ requireQuantity: e.target.checked }, this.saveState)}
            >
              {t('requireQuantity')}
            </Checkbox>
          </Form.Item>
        </Modal>
        <Modal
          title={t('validateIssueStatus')}
          visible={this.state.validateIssueStatusVisible}
          onCancel={() => this.setState({ validateIssueStatusVisible: false })}
          footer={
            <>
              <Button onClick={() => this.setState({ validateIssueStatusVisible: false })}>{t('close')}</Button>
              <Button
                onClick={this.handleValidateIssueStatusSubmit}
                loading={this.state.validateIssueStatusLoading}
                type="primary"
                disabled={isEmpty(this.state.validateIssueStatusSelectedItems)}
              >
                {t('issue')}
              </Button>
            </>
          }
          width={992}
        >
          {isNil(this.state.validateIssueStatusItems) ? (
            <Spin />
          ) : (
            <ValidateStatus
              items={this.state.validateIssueStatusItems ?? []}
              onSelect={(values) => this.setState({ validateIssueStatusSelectedItems: values })}
              settingsType="validateIssueStatus"
              mode="multiple"
            />
          )}
        </Modal>
      </>
    )
  }
}

export default Form.create()(FormView)
